import React from "react"
import Layout from "../components/Layout"

const NotFoundPage = () => (
	<Layout>
		<div className="section">
			<div className="container">
				<div className="columns">
					<div className="column">
						<div className="content">
							<h1>Nicht gefunden</h1>
							<p>Die Seite konnte nicht gefunden werden.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default NotFoundPage
